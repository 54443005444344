import React, { useState, createContext, useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import theme from 'src/theme';
import { advertiseRoutes, publicRoute, userRoutes } from 'src/routes';
import { useSelector } from 'react-redux';
import {
  fetchFromStorage,
  saveToStorage,
  removeFromStorage,
  Config
} from 'src/helpers/context';
import messagesEn from 'src/helpers/locales/en.json';
import messagesDe from 'src/helpers/locales/de.json';
import 'src/assets/scss/global.scss';
import {
  initOneSignalNotification,
  addListenerForNotificationOpened,
  isPushNotificationsEnabled,
  getUserId
  // setDefaultNotificationUrl,
} from 'src/helpers/OneSignal/index';
import { EventEmitter } from 'src/helpers/EventEmitter';
import { useNavigate } from 'react-router-dom';
import { identifiers } from 'src/helpers/constants/identifier';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import getStatusMessage, {
  setApiMessage,
  submitForm
} from './helpers/commonFunctions';

import { SocketContext, socket } from 'src/helpers/context/socket';
import generateSitemap from './generateSitemap';

const Context = createContext();
initOneSignalNotification();
addListenerForNotificationOpened();
isPushNotificationsEnabled();

const App = () => {
  const userRoute = Config?.userRoutePath;
  const navigate = useNavigate();
  const reduxAuth = useSelector(state => state?.reduxData?.authData);
  const reduxLoginUser = useSelector(state => state?.reduxData?.userData);
  const localAuth = fetchFromStorage(identifiers.authData);
  const loginUser = reduxLoginUser
    ? reduxLoginUser
    : fetchFromStorage(identifiers?.userData);
  const deviceID = fetchFromStorage(identifiers.deviceid);
  const auth = reduxAuth ? reduxAuth : localAuth;
  const isCheckInfoRoute =
    window.location.pathname.includes(`${userRoute}info`) ||
    window.location.pathname.includes(`${userRoute}help`) ||
    window.location.pathname.includes(`${userRoute}announcements`) ||
    window.location.pathname.includes(`${userRoute}app`) ||
    window.location.pathname.includes(`${userRoute}privacy-policy`) ||
    window.location.pathname.includes(`${userRoute}agreement`);
  const isCheckAdvRoute = window.location.pathname.includes(`${userRoute}adv`);
  const viewRoutes =
    auth && isCheckInfoRoute
      ? publicRoute
      : auth && isCheckAdvRoute && loginUser?.subclass_id === 0
      ? advertiseRoutes
      : auth && loginUser?.subclass_id === 0
      ? userRoutes
      : auth && loginUser?.subclass_id === 3 && loginUser?.isOutstand === false
      ? advertiseRoutes
      : publicRoute;

  // const viewRoutes =
  //   auth && isCheckInfoRoute
  //     ? publicRoute
  //     : auth && isCheckAdvRoute
  //     ? advertiseRoutes
  //     : auth
  //     ? userRoutes
  //     : publicRoute;

  // const viewRoutes =
  //   auth && isCheckInfoRoute ? publicRoute : auth ? userRoutes : publicRoute;

  const routing = useRoutes(viewRoutes);

  const menuMessages = {
    en: { ...messagesEn },
    de: { ...messagesDe }
    // fr: { ...messagesFr },
    // pt: { ...messagesPt },
  };

  const [locale, setLocale] = useState('en');
  const [messages, setMessages] = useState(menuMessages['en']);
  const [playerId, setPlayerId] = useState('');

  const switchLanguage = lang => {
    saveToStorage(identifiers.acceptlanguage, lang);
    setLocale(lang);
    setMessages(menuMessages[lang]);
  };

  useEffect(() => {
    generateSitemap();
  }, []);

  useEffect(() => {
    var defaultLang =
      fetchFromStorage(identifiers.acceptlanguage) !== null
        ? fetchFromStorage(identifiers.acceptlanguage)
        : 'en';
    setLocale(defaultLang);
    setMessages(menuMessages[defaultLang]);
    saveToStorage(identifiers.acceptlanguage, defaultLang);
    // eslint-disable-next-line
  }, [locale, fetchFromStorage(identifiers.acceptlanguage)]);

  useEffect(() => {
    EventEmitter.subscribe('handlenotificationredirect', event => {
      initOneSignalNotification();
      handleNotificationRedirect(event);
    });
    // eslint-disable-next-line
  }, []);

  // READ NOTIFICATION
  const readNotification = async item => {
    try {
      await axiosInstance.put(URLS.readNotification + `/${item?.id}`);
    } catch (error) {
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  // NOTIFICATION REDIRECT
  const handleNotificationRedirect = item => {
    var notiObj = item?.data?.data?.payload?.type;
    var notiObjData = item?.data?.data;
    const POST_COMMENT = 'comment';
    const POST_LIKES = 'like';
    const FOLLOW = 'follow';
    const POST = 'post';
    const ADVERTISE = 'advertise';

    readNotification(notiObjData);

    switch (notiObj) {
      case POST_COMMENT:
        navigate(
          `${userRoute}post/view/${notiObjData?.payload?.mainUser_id}/${notiObjData?.payload?.post_id}`
        );
        return true;
      case POST_LIKES:
        navigate(
          `${userRoute}post/view/${notiObjData?.payload?.mainUser_id}/${notiObjData?.payload?.post_id}`
        );
        return true;
      case FOLLOW:
        navigate(
          `${userRoute}channel/view/${notiObjData?.payload?.from_userId}`
        );
        return true;
      case ADVERTISE:
        navigate(
          `${userRoute}channel/view/${notiObjData?.payload?.from_userId}`
        );
        return true;
      case POST:
        navigate(
          `${userRoute}post/view/${notiObjData?.payload?.from_userId}/${notiObjData?.payload?.post_id}`
        );
        return true;
      default:
        navigate(
          loginUser?.subclass_id === 3
            ? `${userRoute}adv/notification`
            : `${userRoute}notification`
        );
        return true;
    }
  };

  useEffect(() => {
    var playerID = getUserId();
    Promise.all([playerID]).then(values => {
      setPlayerId(values[0]);
    });
    socket.on('JoinSocket', data => {
      // console.log('JoinSocket', data);
    });
  }, []);

  // ADD DEVICE ID
  const addDeviceID = async () => {
    if (localAuth && !deviceID && playerId) {
      const requestData = {
        deviceId: playerId
      };
      await axiosInstance.post(URLS.registerDeviceId, requestData, {
        headers: {
          Authorization: `Bearer ${localAuth}`,
          'Content-Type': 'application/json'
        }
      });
      saveToStorage(identifiers.deviceid, playerId);
    }
  };
  useEffect(() => {
    addDeviceID();
    // eslint-disable-next-line
  }, [playerId]);

  // PLAY ONLY ONE VIDEO/AUDIO
  useEffect(() => {
    document.addEventListener(
      'play',
      function(e) {
        var videos = document.getElementsByTagName('video');
        var audio = document.getElementsByTagName('audio');
        var i = 0;
        var len = 0;
        for (i = 0, len = videos.length; i < len; i++) {
          if (videos[i] !== e.target) {
            videos[i].pause();
          }
        }
        for (i = 0, len = audio.length; i < len; i++) {
          if (audio[i] !== e.target) {
            audio[i].pause();
          }
        }
      },
      true
    );
  }, []);

  const logout = async () => {
    if (playerId) {
      const requestData = {
        deviceId: playerId
      };
      await axiosInstance.post(URLS.logout, requestData);
    }
    window.location = userRoute;
    removeFromStorage(identifiers.authData);
    removeFromStorage(identifiers.userData);
    removeFromStorage(identifiers.acceptlanguage);
    removeFromStorage(identifiers.expiredTime);
    removeFromStorage(identifiers.subNewList);
    removeFromStorage(identifiers.subNewArray);
  };

  // AUTO LOGOUT
  const handleWithExpiry = () => {
    const itemStr = fetchFromStorage(identifiers.expiredTime);
    if (!itemStr) {
      return null;
    }
    const now = new Date();

    if (now.getTime() > itemStr) {
      logout();
      return null;
    }
    return itemStr;
  };

  useEffect(() => {
    handleWithExpiry();
    // eslint-disable-next-line
  }, []);
  // ERROR REDIRECT
  useEffect(() => {
    // var error = false;
    const handleHeadersDetails = response => {
      const errorCode = response?.status;
      // if (errorCode === 200 && !error) {
      //   error = true;
      //   // GetAPIError();
      // } else
      if (errorCode === 503) {
        navigate(`${userRoute}server-down`);
      } else if (
        errorCode !== 200 &&
        errorCode !== 204 &&
        response?.data?.isApiError
      ) {
        const postData = {
          error: getStatusMessage(errorCode),
          statusCode: errorCode
        };
        if (errorCode !== 400) {
          postData.callBackUrl = window.location.href;
        }
        submitForm(postData);
        // Old Code
        // localStorage.setItem(
        //   'urlstore',
        //   window.location.pathname === '/server-down' ||
        //     window.location.pathname === '/404'
        //     ? '/'
        //     : window.location?.search !== ''
        //     ? window.location.pathname + window.location?.search
        //     : window.location.pathname
        // );
        // navigate(`${userRoute}server-down`);

        // New Code of SSR Issue Page
      }
      // else if (errorCode === 404) {
      //   localStorage.setItem(
      //     'urlstore',
      //     window.location.pathname === '/server-down' ||
      //       window.location.pathname === '/404'
      //       ? '/'
      //       : window.location?.search !== ''
      //       ? window.location.pathname + window.location?.search
      //       : window.location.pathname
      //   );
      //   navigate(`${userRoute}404`);
      // }
    };
    EventEmitter.subscribe('sezzyouerror', handleHeadersDetails);
  }, []);

  // MAINTENANCE REDIRECT
  useEffect(() => {
    const handleMaintenanceUpdated = maintenance => {
      if (maintenance === 'ON') {
        localStorage.setItem(
          'urlstore',
          window.location.pathname === '/server-down' ||
            window.location.pathname === '/404'
            ? '/'
            : window.location?.search !== ''
            ? window.location.pathname + window.location?.search
            : window.location.pathname
        );
        window.location.href = '/maintenance.html';
      }
    };
    EventEmitter.subscribe('maintenanceMode', handleMaintenanceUpdated);
  }, []);

  return (
    <>
      <HelmetProvider>
        <React.Suspense fallback="">
          <ThemeProvider theme={theme}>
            <Context.Provider value={{ locale, switchLanguage, messages }}>
              <SocketContext.Provider value={{ socket }}>
                <GlobalStyles />
                {routing}
              </SocketContext.Provider>
            </Context.Provider>
          </ThemeProvider>
        </React.Suspense>
      </HelmetProvider>
    </>
  );
};

export default App;
export { Context as IntlContext };
