import React from "react";
import { makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import PrivacyPolicy from "src/views/component/application/Info/Policy";
import { IntlContext } from "src/App";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    height: "100%",
  },
}));

const PrivacyPolicyPage = () => {
  const classes = useStyles();
  const intlContext = React.useContext(IntlContext);
  const localesData = intlContext?.messages;

  return (
    <Page className={classes.root} title={localesData.policy.title}>
      <PrivacyPolicy />
    </Page>
  );
};

export default PrivacyPolicyPage;
