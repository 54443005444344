import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import ScrollToTop from './ScrollTop';
import { Config } from 'src/helpers/context';

const userRoute = Config?.userRoutePath;

const Page = forwardRef(
  ({ children, title = '', description = '', ...rest }, ref) => {
    return (
      <div ref={ref} {...rest}>
        {window.location.pathname.includes(`${userRoute}info`) ||
        window.location.pathname.includes(`${userRoute}help`) ||
        window.location.pathname.includes(`${userRoute}adv`) ||
        window.location.pathname.includes(`${userRoute}announcements`) ||
        window.location.pathname.includes(`${userRoute}app`) ||
        window.location.pathname.includes(`${userRoute}privacy-policy`) ||
        window.location.pathname.includes(`${userRoute}agreement`) ? null : (
          <ScrollToTop />
        )}
        <Helmet>
          <title>{title}</title>
          <meta name='description' content={description} />
        </Helmet>
        {children}
      </div>
    );
  }
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Page;
